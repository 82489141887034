user-rights-manager {
  width: 100%;

  p.sso-disabled__warning-message {
    color: $warning-color;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .user-rights-manager--header-text, .user-rights-manager--text {
    font-size: $font-size-small;
  }

  details {
    summary {
      width: 100%;
      .user-rights-manager--text {
        font-weight: 500;
      }
    }
    .user-rights-manager--list-row {
      padding-left: 20px;
    }
  }

  .input-field--checkbox-container {
    align-items: center;

    .input-field__title {
      margin-right: 0px;
    }
    
    .input-field--checkbox {
      min-width: 20px;
      min-height: 20px;
      @include size(20px);
      padding-left: 0px;

      &.half-checked {
        background-image: url('/imgs/icons/icon-checked-half-bold@3x.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 9px;
      }
      &.checked {
        background-image: url('/imgs/icons/icon-checked-bold@2x.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px 9px;
      }
    }
  }

  .user-rights-manager--header {
    flex-wrap: wrap;
  }
  .user-rights-manager--header, .user-rights-manager--list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;
    gap: 7px;

    .input-field--checkbox-container {
      margin-bottom: 0px;
    }
  }

  .user-rights-manager--list+.user-rights-manager--header {
    margin-top: 10px;
  }

  .user-rights-manager--right {
    display: flex;
    flex: 0 0 calc(75px * 3);
    justify-content: space-between;
    align-content: center;
    .user-rights-manager--header-text {
      width: 75px;
      display: inline-block;
    }

    .input-field--checkbox-container {
      margin-bottom: 0px;
      width: 75px;
      justify-content: center;
      .input-field--checkbox {
        margin-right: 0;
      }
    }

    .input-field {
      text-align: left;
    }

    .user-rights-manager--text {
      margin-left: 25px;
    }

    .comp__filter {
      text-align: left;
      margin-left: 25px;
      width: 100%;
    }
  }


  //summary behavior changes
  summary .user-rights-manager--text::after {
    content: '    ';
    white-space: pre;
    background: url('/imgs/icons/icon-full-arrow-black-bottom@2x.png') right center / 12px 6px no-repeat;
  }
  details[open] summary .user-rights-manager--text:after {
      content: '    ';
      white-space: pre;
      background: url('/imgs/icons/icon-full-arrow-black-top@2x.png') right center / 12px 6px no-repeat;
  }
  summary::after, details[open] summary:after {
    content: inherit;
    white-space: inherit;
    background: inherit;
  }
}