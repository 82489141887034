/*
---
name: Candidate field
category: fields/profiles
tag:
  - Fields
---

```jade
// Pending submission
.profile-field
    .profile-field__profile
        img.profile-photo.profile-field__photo(src='/imgs/profile-marc@2x.png')
        .profile-field__info
            .label.label--l.profile-field__name Marc Chavanne
            .label.label--thin.profile-field__details Braine-L’alleud, Belgique
    .profile-field__bookmark.icon.icon--bookmark
    .profile-field__status Pending submission
    .profile-field__time
        span 15:01

// Stars
.profile-field
    .profile-field__profile
        img.profile-photo.profile-field__photo(src='/imgs/profile-samantha@2x.png')
        .profile-field__notification 2
        .profile-field__info
            .label.label--l.profile-field__name Samanta Delvaux
            .label.label--thin.profile-field__details Braine-L’alleud, Belgique
    .profile-field__bookmark.icon.icon--bookmark
    .profile-field__stars
        .icon.icon--star.icon--star--filled
        .icon.icon--star.icon--star--filled
        .icon.icon--star.icon--star--filled
        .icon.icon--star.icon--star--filled
        .icon.icon--star
    .profile-field__time
        span 2d ago
```
*/

.profile-field {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 3px;
    overflow-wrap: anywhere;
    word-break: break-word;
    background-color: #edeff1;
    border: solid 1px rgba(226, 229, 232, 0.4);
    border-radius: 3px;
}

.profile-field__new {
    background-color: white;
}

.profile-field--menu {
    @include size(100% 60px);
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;

    .bulk-actions--separator {
        &::after { 
            content: "";
            width: 1px;
            height: 45px;
            // uncomment to show the vertical line 
            // background: linear-gradient(90deg, rgba(255,255,255,0.0), $charcoal-grey);
            margin-right: 30px;
            margin-left: 10px
        }
    }
}
    
    .bulk-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .bulk-icons {
        display: flex;
    }

    .bulk__icon {
        margin-top: 5px; // compensate for tooltip weird tilting
        margin-left: 3px;
        margin-right: 3px;
    }

    .profile-field__profile {
        display: flex;
        flex-basis: 100%;
        align-items: center;
    }
        .profile-field__photo {
            min-width: 43px;
            max-width: 43px;
            margin-right: 15px;
        }

        .profile-field__notification {
            // position: absolute;
            @include size(26px);
            line-height: 23px;
            background-color: #56595c;
	        border: solid 2px #ffffff;
            margin: -9px 15px 0 -40px;
            border-radius: 100px;
            color: #fff;
            font-size: $font-size-smaller;
            text-align: center;
        }

        .profile-field__info {
            padding-top: 3px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // align-items: center;
        }

            .profile-field__info-row {
                flex-direction: row;
                // width: 100%;
            }

            .profile-field__name {
                font-size: $font-size-small;
                margin-bottom: 3px;
                margin-right: 10px;
                margin-right: 10px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 80px;
                max-width: 120px;
            }
            .profile-field__name--large {
                min-width: 300px;
                max-width: 300px;
            }

    .profile-field__checkbox {
        max-width: 35px;
    }

    .profile-field__bookmark {
        height: 50px;
        margin-right: 10px;
        // flex-basis: 20px;
        //border: 1px solid red;
    }

    .profile-field__status-container, .candidates-profiles__filters--columns {
        .profile-field__assessfirst {
            min-width: 120px;
        }

        .profile-field__docimo {
            min-width: 120px;
        }

        .profile-field__interview {
            min-width: 120px;
        }

        .profile-field__interview-badge {
            width: 30px;
        }

        .profile-field__tag {
            min-width: 175px;
            
            .tooltips._ready {
                .tags-tooltip-item {
                    padding: 5px 0;
                    min-width: 175px;
                    b {
                        color: #fff
                    }
                    &+.tags-tooltip-item {
                        border-top: 1px inset;
                    }
                }
            }
        }

        .profile-field__campaign {
            min-width: 160px;
            max-width: 160px;
        }

        .profile-field__country {
            min-width: 140px;
            max-width: 140px;
        }

        .profile-field__addedBy {
            min-width: 160px;
            max-width: 160px;
        }

        .profile-field__stage {
            min-width: 140px;
        }

        .profile-field__matching {
            min-width: 90px;
        }

        .profile-field__rating {
            min-width: 120px;
        }

        .profile-field__time {
            min-width: 140px;
        }
    }

    .profile-field__status-container {
        display: flex;
        margin-left: auto;
        justify-content: flex-end;
        align-items: center;
        //border: 1px solid red;
    }

        .profile-field__item {
            font-size: $font-size-small;
            color: $black;
            text-align: center;
            padding: 0 5px;

        }

        .profile-field__item.profile-field__campaign {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .profile-field__extra {
            min-width: 50px;
            margin-top: auto;
            margin-bottom: auto;
        }

        .profile-field__interview {
            display: flex;
            justify-content: center;
            margin-top: auto;
            margin-bottom: auto;
        }

        .profile-field__interview-badge {
            border-radius: 50%;
            display: flex;
            align-items: center;
        }

        .profile-field__tag {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }

        .candidate-stage {
            font-size: $font-size-small;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 8px;
            background-color: $stage-color-default;
            color: white;
        }
        .candidate-stage--dd {
            @include append__arrow-down(0px, white);
        }
        .candidate-stage--dd-view {
            &::after {
                content: none;
            }
        }
        .candidate-stage--dd {
            .candidate-stage--dd-text span {
                display: inline-block;
                width: 94px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: left;
                margin-top: 2px;
                margin-bottom: -2px;
            }
        }
        .wrap-dd-menu .candidate-stage--dd + .dropdown {
            width: auto;
        }
        .candidate-stage--in-dd {
            margin-top: -10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 500px;
        }
        .sidebar-profile {
            .wrap-dd-menu .candidate-stage--dd + .dropdown {
                width: auto;
                right: -45px;
            }
            .candidate-stage--in-dd {
                max-width: 200px;
            }
        }
            .candidate-stage__icon {
                background-color: $stage-color-default;
                color: white;
                padding: 7.5px;
            }
        .stage-date {
            margin-left: 10px;
            font-size: $font-size-small;
        }

        .profile-field__rating {
            display: flex;
            
            .profile-field__rating-text {
                font-size: $font-size-small;
                color: #9aa2a8;
            }
            
            .profile-field__rating-stars {
                display: flex;
                justify-content: center;
                align-items: center;
                
                span {
                    font-size: $font-size-small;
                    padding-top: 5px; // to be aligned with the stars
                }

                .profile-field__stars {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .icon {
                        margin: 0 2px;
                    }
                }
            }
        }

        .profile-field__time {
            text-align: center;
        }

/*
---
name: Collaborators field
category: fields/profiles
tag:
  - Fields
---

```jade
// Pending submission
.profile-field
    .profile-field__profile
        img.profile-photo.profile-field__photo(src='/imgs/profile-marc@2x.png')
        .profile-field__info
            .label.label--l.profile-field__name Marc Chavanne
            .label.label--thin.profile-field__details Sales manager
    .profile-field__menu.icon.icon--menu.icon--menu--black

// Pending
.profile-field
    .profile-field__profile
        img.profile-photo.profile-field__photo(src='/imgs/profile-marc@2x.png')
        .label.label--l.profile-field__name.profile-field__name--pending nick.goldstein@gmail.com (pending)
    .profile-field__menu.icon.icon--menu.icon--menu--black

```
*/


.profile-field__name--pending {
    margin: 0;
    line-height: 1;
    align-self: center;
    color: $warm-grey-two;
}
.profile-field__name--archived {
    font-family: $font-family-main-book;
    color: $warm-grey-two;
}
