.campaign-vonq-marketplace {
    display: flex;
    flex-direction: column;
    width: 100%;

    .input-field.filter__item__dropdown span {
        display: inline-block;
        margin-top: 10px;
    }

    .vonq-orders-history {
        margin-bottom: 30px;
    }
    
    & .filterRow, & .sortRow {
        flex: 0;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .cart__filter+.cart__filter {
            margin-left: 70px;
        }

        .filter__item__search {
            margin-left: 0px;
        }
        .filter__item__dropdown {
            width: 20%;
            margin-bottom: 1rem;
        }

        .btn-search {
            margin-left: 70px;
        }

        .input-field {
            margin: 0;
        }
    }

    & .main-container {
        display: flex;
        justify-content: space-between;

        .cart-container {
            flex-grow: 0;
            flex-shrink: 0;
            width: 420px;
            background-color: #fff;
            border-radius: 5px;
            height: fit-content;
            padding: 20px;
            
            .btn-checkout {
                width: 280px;
                // margin-left: -10px;
                // margin-right: -10px;
                margin: 20px auto 0px;
            }
        }

        .vonq-marketplace__products {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .product-list {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 30px;

            .product-card {
                min-width: 250px;
                width: 20%;
                padding: 20px;
                background-color: #fff;
                border-radius: 5px;

                display: flex;
                gap: 10px;
                flex-direction: column;
                align-content: center;
                justify-content: space-between;

                .price-duration {
                    display: flex;
                    justify-content: space-evenly;
                    width: 100%;
                    // margin-top: 10px;
                }

                .product-card__title {
                    display: flex;
                    justify-content: space-between;
                }

                .product-card__logo {
                    text-align: center;

                    img {
                        width: auto;
                        max-height: 50px;
                        max-width: 200px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }

                .product-card__type {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                &.highlight-card {
                    border: 3px outset #FFE70C;
                }
            }
        }

        .product-list__paginator {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
        }

        .badges-row {
            margin: 2px 0px;
        }
    }

    & .vonq-orders-history__table {
        margin: 10px 0;
        width: 100%;
        border-spacing: 0 5px;
        border-collapse: separate;

        & thead {
            text-align: left;
        }

        & tr {
            background-color: #fff;
        }

        & th, & td {
            padding: 20px;

            &.align-right {
                text-align: right;
            }
            &.align-center {
                text-align: center;

                .badge {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            &:first-child, &:last-child {
                padding: 10px 15px;
            }
        }
    }
}
