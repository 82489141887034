.kanban-view {
  display: inline-block;
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-wrap: nowrap;

  .kanban-view__checkbox {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 9px;

    &.half-checked {
      background-image: url('/imgs/icons/icon-checked-half-bold@3x.png');
    }

    &.checked {
      background-image: url('/imgs/icons/icon-checked-bold@2x.png');
    }
  }

  .kanban-view__header, .kanban-view__body {
    display: flex;
    gap: 5px;
    flex-wrap: nowrap;
  }
  
  .kanban-view__column {
    flex: 0 0 300px;
    max-width: 300px;

    &.dndDragover {
      background-color: #DCD9D9;
    }
  }
  .kanban-view__header-column {
    background-color: $brand-yellow;
    font-family: $font-family-main-medium;
    border-radius: 3px;
    border: solid 1px rgb(248, 248, 248);
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    .kanban-view__header-checkbox {
      margin-left: auto;
      flex: 0 0;
      .input-field--checkbox {
        margin-right: 0px;
      }
    }
  }
  .kanban-view__body-column {
    background-color: #fff;
    border-radius: 3px;
    border: solid 1px rgba(226, 229, 232, 0.4);
    padding: 10px;
    min-height: 400px;
  }

  .kanban-view__item-wrapper {
    &:hover {
      .kanban-view__item {
        box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.2);
        bottom: 5px;
      }
    }
  }

  .kanban-view__item {
    position: relative; // for the transition with hover
    bottom: 0px; // for the transition with hover
    transition-duration: .1s;
    border: 1px solid $grey;
    border-radius: 3px;
    background-color: #fff;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    gap: 10px;
    

    &:first-child {
      margin-top: 0px;
    }

    // &:hover {
    //   box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.2);
    //   bottom: 5px;
    // }

    .kanban-view__item-image {
      flex: 0 0;
      img {
        @include size(43px);
        margin: 0 5px;
        border-radius: 50%;
      }
    }
    .kanban-view__item-title {
      font-family: $font-gotham-bold;
      font-weight: 700;
      width: 100%;
      word-break: break-word;
      margin-top: 5px;
      a {
        line-height: 1.4rem;
      }
    }
    .kanban-view__item-subtitle {
      font-family: $font-gotham-light;
      font-size: $font-size-small;
      color: #9AA2A8;
      width: 100%;
      margin-top: 3px;
    }
    .kanban-view__item-checkbox {
      margin-left: auto;
      flex: 0 0;
      .input-field--checkbox {
        margin-right: 0px;
      }
    }

    .kanban-view__item-content {
      display: flex;
      flex-direction: column;
    }

    .kanban-view__score {
      display: flex;
      align-self: flex-start;
      align-items: center;
      gap: 10px;
      min-height: 40px;
      .kanban-view__item-rating {
        width: 110px;
      }
      .kanban-view__item-matching {

      }
    }

    .kanban-view__actions {
      display: flex;
      gap: 10px;

      .kanban-view__actions-item {
        padding: 4px 0 0;
      }
    }
  }

  .kanban-view__item--drag {
    opacity: 0.7;
  }
}

.display-mode--toggle {
  cursor: pointer;
  
  .display-mode__item {
    //@include size(58px);
    background-color: #edeff1;
    border: 1px solid #eff0f2;
    border-radius: 3px;
    color: $black;
    display: inline-block;
    padding: 10px;
    .icon {
      font-size: 20px;
    }
  }
  .display-mode--active {
    background-color: #fff;
  }
}