.candidate-message__section {
    width: 100%;

    summary h4 {
        // necessary to show the arrowhead of details summary
        display: inline;
    }

    & + & {
        margin-top: 30px;
    }

    tooltip {
        position: absolute;
        margin-left: 50px;
    }

    &.live-interview {
        margin-bottom: 20px;
    }
}

    .candidate-message__text {
        margin-top: 10px;
    }

    .candidate-message__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
        margin-top: 10px;
        gap: 5px
        // padding: 1rem 0;
    }

    .candidate-message__item {
        cursor: pointer;
        margin: 0 5px;
        &.active {
            font-family: $font-family-main-medium;
        }
    }
    .candidate-message__item--underlined {
        text-decoration: underline;
    }
    .candidate-message__customization {
        background-color: #52b3eb;
    }
