.submission-messages {

}

  .messages__bubble {
    min-height: fit-content;
    min-width: 200px;
    max-width: 500px;
    padding: 20px 30px;
    border-radius: 5px;
    overflow-wrap: break-word;
    position: relative;

    p + p {
      padding-top: 0;
    }

    a {
      color: $brand-blue;
      text-decoration: underline;
    }
  }
  
  .messages__actions {
    position: absolute;
    right: 11px;
    top: -5px;

    &.dropdown__container--black {
      .dropdown {
        padding: 5px;
      }
    }
  }

  .messages__actions__icon {
    @include size(24px);
    border: 1px solid #e2dfdf;
    .icon-in-circle {
      @include size(12px);
      margin: auto;
      background-position: center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

    .messages__bubble--recruiter {
      @include beehire-formatted-html($white);
      background-color: $brand-yellow;
      border-bottom-right-radius: 0;
      color: $white;
      margin-left: auto;
      p {
        color: $white;
      }
    }

    .messages__bubble--candidate {
      @include beehire-formatted-html(inherit);
      background-color: $grey-light;
      border-bottom-left-radius: 0;
    }

      .messages__header {
        margin: 0 10px 0 10px;
        width: fit-content;
      }

      .messages__header--recruiter {
        margin: 0 10px 0 auto;
      }

      .messages__body {
        white-space: pre-line;
      }

      .messages-attachments {
        margin-top: 10px;
      }

      .messages-attachments--recruiter:not(:empty) {
        padding: 10px 20px;
      }

      .messages-attachment {
        width: fit-content;
        font-size: $font-size-small;
        background-color: $white;
        padding: 5px 15px;
        border-radius: 5px;
        
        & + & {
          margin-top: 10px;
        }
      }

    .submission-messages__add {
      margin: 0px 30px;
      border-top: 1px solid rgba(0,0,0,.09);
    }

    .messages__container {
      margin-bottom: 10px;
       h4 {
        // necessary to show the arrowhead of details summary
        display: inline;
        margin-bottom:6px;
      }
    }

    .messages__templates__predefined-message{
      font-size:14px;
      margin-bottom: 6px;
    }

      .messages__templates {
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .candidate-message__content {
          justify-content: start;
        }
      }
    
      .messages__dragover {
        border: 3px dashed $grey-dark; 
      }

    .messages__input-field {
      padding: 20px;
      border-top: none;
      margin-bottom: 0;
    }

    .messages__input {
      display: inline-block;
      width: 100%;
      min-height: 65px;
      outline: none;
    }

    .messages__templates-candidate {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .messages__subject-candidate {
      align-self: flex-start;
      flex: 1;
      margin-right: 10px;
    }
    
    .messages__predefined-candidate {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: -1rem;
      margin-left: 30px;
    }
